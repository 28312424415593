import { graphql } from "gatsby"

import About from "../components/about"

export default About

export const query = graphql`
  query AboutPageQuery(
    $slug: String
  ) {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    blogPost(slug: { eq: $slug }) {
      id
      body
      slug
    }
  }
`