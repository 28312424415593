import * as React from "react"
import Layout from "./layout"
import SEO from "./seo"
import Footer from "./home-footer"
import { Grid } from "./grid"
import { MDXRenderer } from "gatsby-plugin-mdx"

const PostsWrapper = ({ location, data }) => {
  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
    <SEO title="关于" />
    <Grid as="main" className="prose prose-light mb-24 dark:prose-dark">
    <MDXRenderer >{data?.blogPost?.body}</MDXRenderer>
    </Grid>
   
    <Footer  />
  </Layout>
  )
}

export default PostsWrapper
